<template>
  <div id="clothSheet" class="clothSheet">
    <!-- 顶部筛选 -->
    <div class="filter">
      <div class="left">
        <div class="filterItem">
          <div style="text-align: right;margin-right:10px">计划单号</div>
          <Input placeholder="请输入搜索内容" v-model.trim="ztgcpoPlanOrderNumber" @keyup.enter.native="handleChangeFilter()" style="width: 150px" />
        </div>
        <div class="filterItem">
          <div style="text-align: right;margin-right:10px">合同号</div>
          <Input placeholder="请输入搜索内容" v-model="ztgcpoContractNo" @keyup.enter.native="handleChangeFilter()" style="width: 150px" />
        </div>
        <div class="filterItem">
          <div style="text-align: right;margin-right:10px">坯布货号</div>
          <Input placeholder="请输入搜索内容" v-model="ztgcpoiProductionCargoNum" @keyup.enter.native="handleChangeFilter()" style="width: 150px" />
        </div>
        <!-- <div class="filterItem">
          <div style="text-align: right;margin-right:10px">成品品号</div>
          <Input placeholder="请输入搜索内容" v-model="ztgcoArticleNumber" @keyup.enter.native="handleChangeFilter()" style="width: 150px" />
        </div> -->
        <div class="filterItem">
          <div style="text-align: right;margin-right:10px">坯布指示单号</div>
          <Input placeholder="请输入搜索内容" v-model.trim="ztgcpoiWeaveDetail" @keyup.enter.native="handleChangeFilter()" style="width: 150px" />
        </div>
                <div class="filterItem">
          <div style="text-align: right;margin-right:10px">业务担当</div>
          <Input placeholder="请输入搜索内容" v-model.trim="ztgcpoTakeOn" @keyup.enter.native="handleChangeFilter()" style="width: 150px" />
        </div>
        <div class="filterItem">
          <div style="text-align: right;margin-right:10px">订单交期</div>
          <DatePicker
            type="daterange"
            v-model="ztgcpoOrderDeliveryDate"
            placeholder="请选择"
            style="width: 220px"
            @keyup.enter.native="handleChangeFilter()"
          ></DatePicker>
        </div>
        <div class="filterItem">
          <div style="text-align: right;margin-right:10px">交货日期</div>
          <DatePicker
            type="daterange"
            v-model="ztgcpoDeliveryDate"
            placeholder="请选择"
            style="width: 220px"
            @keyup.enter.native="handleChangeFilter()"
          ></DatePicker>
        </div>
        <div class="filterItem">
        <div style="margin-right:10px;min-width:56px">计划日期</div>
        <DatePicker
          v-model="ztgcpoPlanDate"
          format="yyyy-MM-dd"
          type="daterange"
          placeholder="请选择日期范围"
          style="width: 220px"
          @on-change="handleChangeFilter()"
        ></DatePicker>
      </div>
      <div class="filterItem">
        <Button @click="handleClickfilter">
          自定义筛选
          <i v-if="!showUserDefined" class="iconfont iconicon-xia"></i>
          <i v-if="showUserDefined" class="iconfont iconicon-shang"></i>
        </Button>
      </div>
      </div>
    </div>
    <!--自定义筛选显示-->
    <div class="userDefined" v-if="showUserDefined">
      <div class="left">
        <div class="select_container" v-if="showSelect" style="margin-bottom:10px;">
          <Checkbox
            :indeterminate="indeterminate1"
            :value="checkAll1"
            @click.prevent.native="handleCheckAll1"
          >全选</Checkbox>
          <div class="divide"></div>
          <CheckboxGroup v-model="checkAllGroup1" @on-change="checkAllGroupChange1">
            <Checkbox :label="item.name" v-for="item in boxList1" :key="item.name"></Checkbox>
          </CheckboxGroup>
        </div>

        <div class="filter_container" v-else>
          <div class="filterItem" v-if="checkAllGroupShow1.includes('工厂名称')">
            <div style="text-align: right;margin-right:10px">工厂名称</div>
            <Input placeholder="请输入搜索内容" v-model.trim="ztgcpoFactoryName" @keyup.enter.native="handleChangeFilter()" style="width: 150px" />
          </div>
          <div class="filterItem" v-if="checkAllGroupShow1.includes('种类')">
            <div style="text-align: right;margin-right:10px">种类</div>
            <Input placeholder="请输入搜索内容" v-model.trim="ztgcpoiClothType" @keyup.enter.native="handleChangeFilter()" style="width: 150px" />
          </div>
          <div class="filterItem" v-if="checkAllGroupShow1.includes('面料颜色')">
            <div style="text-align: right;margin-right:10px">面料颜色</div>
            <Input placeholder="请输入搜索内容" v-model.trim="ztgcpoFabriColor" @keyup.enter.native="handleChangeFilter()" style="width: 150px" />
          </div>

          <div class="filterItem" v-if="checkAllGroupShow1.includes('客户款号')">
            <div style="text-align: right;margin-right:10px">客户款号</div>
            <Input placeholder="请输入搜索内容" v-model.trim="ztgcpoiClientNum" @keyup.enter.native="handleChangeFilter()" style="width: 150px" />
          </div>

          <div class="filterItem" v-if="checkAllGroupShow1.includes('计划日期')">
            <div style="text-align: right;margin-right:10px">计划日期</div>
            <DatePicker
              type="daterange"
              v-model="ztgcpoPlanDate"
              placeholder="请选择"
              style="width: 220px"
              @keyup.enter.native="handleChangeFilter()"
            ></DatePicker>
          </div>

          <div class="filterItem" v-if="checkAllGroupShow1.includes('担当')">
            <div style="text-align: right;margin-right:10px">担当</div>
            <Input placeholder="请输入搜索内容" v-model.trim="ztgcpoTakeOn" @keyup.enter.native="handleChangeFilter()" style="width: 150px" />
          </div>

          <div class="filterItem" v-if="checkAllGroupShow1.includes('状态')">
            <div style="text-align: right;margin-right:10px">状态</div>
            <Select
              slot="list"
              style="width: 210px"
              v-model="ztgcpoStatus"
              @on-change="handleChangeFilter()"
            >
              <Option v-for="item in statusList" :key="item" :value="item">{{
                item
              }}</Option>
            </Select>
          </div>
        </div>
      </div>
      <div class="right">
        <!-- <Button class="cancel" v-if="showSelect" @click="handleClickCancel">取消</Button> -->
        <Button class="confirm" v-if="showSelect" @click="handleClickConfirm">确定</Button>
      </div>
    </div>
    <!-- 状态单选 -->
    <!-- <div class="box">
      <div class="boxItem">
        <span style="margin-right: 10px">状态：</span>
        <Checkbox
          :indeterminate="indeterminate2"
          :value="checkAll2"
          @click.prevent.native="handleCheckAll2"
        >全选</Checkbox>
        <CheckboxGroup v-model="checkAllGroup2" @on-change="checkAllGroupChange2">
          <Checkbox :label="item.name" v-for="item in boxList2" :key="item.name"></Checkbox>
        </CheckboxGroup>
      </div>

    </div> -->

    <!-- 按钮 -->
    <div class="button">
      <div class="buttonRow">
        <div class="left">
          <div class="item" @click="tableDataList()">
            <i class="iconfont iconicon-shuaxin" />
            刷新
          </div>
          <div class="item" @click="handleClickExport()">
            <i class="iconfont iconicon-daochu" />
            导出
          </div>
          <div class="item" @click="handleClickTableSetting">
            <i class="iconfont iconicon-chuansuokuang" />
            表格设置
          </div>
          <div class="divide"></div>
          <div class="item" @click="handleClickSync">
            <i class="iconfont iconicon-tongbu" />
            同步
          </div>
          <!-- <div class="item">
            <i class="iconfont iconicon-xiazai" />
            下载模板
          </div> -->
          <!-- <div class="item">
            <i class="iconfont iconicon-daoru" />
            导入
          </div> -->
        <div class="divide"></div>
          <div class="item" @click="tableDataList">
            <i class="iconfont iconquerenshaixuan" />
            确认筛选
          </div>
        </div>
        <div class="right">
        </div>
      </div>
      <!-- 可以扩展  -->
    </div>

    <!-- 表格 -->
    <Table :columns="tableColumns" :loading="loading" :data="tableData" border></Table>

    <!-- 分页 -->
    <Page
      class-name="page"
      :total="listData.total"
      show-elevator
      show-sizer
      show-total
      :page-size="listData.pageSize"
      :current="listData.pageNum"
      @on-page-size-change="pageSizeChange"
      @on-change="pageNumberChange"
    />
    <!-- 表格设置 -->
    <TableSetting
      :chuansuoDialogVisible.sync="chuansuoDialogVisible"
      :routerPath="routerPath"
      :theadDateReal="theadDateReal"
      @tableDataList="tableDataList"
    />
  </div>
</template>

<script>
import TableSetting from "../../components/home/tableSetting.vue";
export default {
  components: {
    TableSetting
  },
  data() {
    return {
      statusList: [], // 状态下拉框

      loading: false, // 是否在加载中
      routerPath: "/dtsum/zongtong/order/planController/clothSheetSearch",
      theadDateReal: [],
      chuansuoDialogVisible: false, // 表格设置显示
      ztgcpoiProductionCargoNum: "",
      ztgcpoPlanOrderNumber: "", // 坯布指示单
      ztgcpoContractNo: "", // 合同号
      ztgcpoGreyClothItemNo: "", // 坯布货号
      ztgcoArticleNumber: "", // 成品品号
      ztgcpoiWeaveDetail: "", // 计划单号
      ztgcpoOrderDeliveryDate: [], // 订单交期
      ztgcpoDeliveryDate: [], // 交货日期

      ztgcpoFactoryName: "", // 工厂名称
      ztgcpoiClothType: "", // 种类
      ztgcpoFabriColor: "", // 面料颜色
      ztgcpoiClientNum: "", // 客户款号
      ztgcpoPlanDate: [], // 计划日期
      ztgcpoTakeOn: "", // 担当
      ztgcpoStatus: "", // 状态

      filterList: [
        // 条件筛选的列表
        {
          number: 1,
          name: "工单单号"
        },
        {
          number: 2,
          name: "订单单号"
        }
      ],
      yesOrNo: [
        {
          name: "是"
        },
        {
          name: "否"
        }
      ],

      showUserDefined: false, // 是否展示自定义筛选模块
      showSelect: false, // true展示多选框，false展示筛选条件

      indeterminate1: false, // 只负责样式控制,true为是
      checkAll1: false, // 是否全选
      checkAllGroup1: [], // 已选的多选框的存储
      checkAllGroupShow1: [], // 筛选条件已选的存储

      boxList1: [
        // 多选框的列表
        {
          name: "工厂名称"
        },
        {
          name: "种类"
        },
        {
          name: "面料颜色"
        },
        {
          name: "客户款号"
        },
        {
          name: "计划日期"
        },
        {
          name: "担当"
        },
        {
          name: "状态"
        }
      ],

      indeterminate2: false, // 只负责样式控制,true为是
      checkAll2: false, // 是否全选
      checkAllGroup2: [], // 已选的多选框的存储

      boxList2: [
        // 多选框的列表
        {
          name: "待穿纱"
        },
        {
          name: "待改机"
        },
        {
          name: "量产中"
        }
      ],
      tableColumnsReady: [
        {
          title: "计划单号",
          key: "ztgcpoPlanOrderNumber",
          minWidth: 130,
          align: "left"
        },
        {
          title: "坯布指示单号",
          key: "ztgcpoiWeaveDetail",
          minWidth: 130,
          align: "left"
        },
        {
          title: "条形码",
          key: "ztgcpoiBarcode",
          minWidth: 100,
          align: "left"
        },
        {
          title: "坯布货号",
          key: "ztgcpoiProductionCargoNum",
          minWidth: 130,
          align: "left"
        },
        // {
        //   title: "坯布货号",
        //   key: "ztgcpoGreyClothItemNo",
        //   minWidth: 150,
        //   align: "left"
        // },
        {
          title: "业务担当",
          key: "ztgcpoTakeOn",
          minWidth: 100,
          align: "left"
        },
        {
          title: "计划日期",
          key: "ztgcpoPlanDate",
          minWidth: 180,
          align: "left"
        },
        {
          title: "合同号",
          key: "ztgcpoContractNo",
          minWidth: 150,
          align: "left"
        },
        {
          title: "门幅",
          key: "ztgcpoiLarghezza",
          minWidth: 150,
          align: "left"
        },
        {
          title: "克重",
          key: "ztgcpoiGram",
          minWidth: 100,
          align: "left"
        },
        {
          title: "机型",
          key: "ztgcpoModel",
          minWidth: 100,
          align: "left"
        },
        {
          title: "种类",
          key: "ztgcpoiClothType",
          minWidth: 100,
          align: "left"
        },
        {
          title: "订单需求数",
          key: "ztgcpoiOrderDemandNum",
          minWidth: 150,
          align: "left"
        },
        {
          title: "面料颜色",
          key: "ztgcpoFabriColor",
          minWidth: 200,
          align: "left"
        },
        {
          title: "客户款号",
          key: "ztgcpoiClientNum",
          minWidth: 200,
          align: "left"
        },
        {
          title: "匹数",
          key: "ztgcpoiClothNum",
          minWidth: 100,
          align: "left"
        },
        {
          title: "匹重",
          key: "ztgcpoiClothWeight",
          minWidth: 100,
          align: "left",
          render: (h, params) => {
            return h('span',
                params.row.ztgcpoiClothWeight.toFixed(2)
            );
          }
        },
        {
          title: "计划数",
          key: "ztgcpoiPlanNum",
          minWidth: 100,
          align: "left"
        },
        {
          title: "挡车工工价",
          key: "ztgcpoiMachinistLabourCost",
          minWidth: 120,
          align: "left"
        },
        {
          title: "织造总损",
          key: "ztgcpoiWeaveLose",
          minWidth: 100,
          align: "left"
        },
        {
          title: "单价",
          key: "ztgcpoiPrice",
          minWidth: 100,
          align: "left"
        },
        {
          title: "成份",
          key: "ztpdComponent",
          minWidth: 210,
          align: "left"
        },
        {
          title: "台机产量值",
          key: "ztgcpoiMachineOutput",
          minWidth: 120,
          align: "left"
        },
        {
          title: "成品损耗率",
          key: "ztgcpoiProductionLoseRate",
          minWidth: 120,
          align: "left"
        },
        {
          title: "序号",
          key: "ztgcpoiSerialNum",
          minWidth: 100,
          align: "left"
        },
        {
          title: "系数",
          key: "ztgcpoiCoefficient",
          minWidth: 100,
          align: "left"
        },
        {
          title: "上机费",
          key: "ztgcpoiPowerPrice",
          minWidth: 100,
          align: "left"
        },
        {
          title: "转数/KG",
          key: "ztgcpoiKgRunSpeed",
          minWidth: 100,
          align: "left"
        },
        {
          title: "单位",
          key: "ztgcpoiUnit",
          minWidth: 100,
          align: "left"
        },
        {
          title: "订单交期",
          key: "ztgcpoOrderDeliveryDate",
          minWidth: 120,
          align: "left"
        },
        {
          title: "交货日期",
          key: "ztgcpoDeliveryDate",
          minWidth: 120,
          align: "left"
        },
        {
          title: "备注",
          key: "ztgcpoRemarks",
          minWidth: 200,
          align: "left"
        },
        {
          title: "生产备注",
          key: "ztgcpoProductionRemarks",
          minWidth: 200,
          align: "left"
        },
        {
          title: "工厂名称",
          key: "ztgcpoFactoryName",
          minWidth: 200,
          align: "left"
        },
        {
          title: "工厂编号",
          key: "ztgcpoFactoryNumber",
          minWidth: 100,
          align: "left"
        },
        {
          title: "条距",
          key: "ztgcpoSliverSpacing",
          minWidth: 200,
          align: "left"
        },
        // {
        //   title: "成品货号",
        //   key: "ztgcpoiProductionCargoNum",
        //   minWidth: 200,
        //   align: "left"
        // },
        {
          title: "额定转数",
          key: "ztgcpoiCheckedRunSpeed",
          minWidth: 200,
          align: "left"
        },
        {
          title: "客户编号",
          key: "ztgcpoCustomerNumber",
          minWidth: 200,
          align: "left"
        },
        {
          title: "客户名称",
          key: "ztgcpoCustomer",
          minWidth: 200,
          align: "left"
        },
      ],
      tableColumns: [],
      tableData: [], // 主表的数据
      listData: {
        // 主表的分页数据
        pageSize: 10,
        pageNum: 1,
        total: 0
      },
      userFilds: [] // 主表的显示的表格头
    };
  },
  mounted(){
    this.tableDataList()
    this.commonSelect()
  },
  activated(){
    this.tableDataList()
    this.commonSelect()
  },
  methods: {
    // 获取状态
    commonSelect(){
      this.axios({
        url: '/dtsum/zongtong/basic/CommonController/commonSelect',
        method: 'get',
        params: {
          flag: 'orderstatus'
        }
      }).then((res) => {
        if (res.data.success === 1){
          this.statusList = res.data.body.selectList
        }
      }).catch((err) => {
        console.log(err)
      })
    },
    // 修改筛选条件
    handleChangeFilter(params){
      this.tableDataList()
    },
    // 同步
    handleClickSync(){
      this.axios({
        url: "/dtsum/zongtong/basic/CommonController/synTable",
        method: 'get',
      }).then((res) => {
        if (res.data.success === 1){
          this.$Message.success('同步成功！')
        } else {
          this.$Message.warning(res.data.msg)
        }
      }).catch((err) => {
        console.log(err)
      })
    },
    // 导出
    handleClickExport() {
      window.location.href = `${this.baseUrl}/dtsum/zongtong/order/planController/clothSheetExport?ztgcpoPlanOrderNumber=${this.ztgcpoPlanOrderNumber}&ztgcpoGreyClothItemNo=${this.ztgcpoGreyClothItemNo}&ztgcoArticleNumber=${this.ztgcoArticleNumber}&ztgcpoiWeaveDetail=${this.ztgcpoiWeaveDetail}&ztgcpoOrderDeliveryDateStart=${this.ztgcpoOrderDeliveryDate[0] ? this.moment(this.ztgcpoOrderDeliveryDate[0]).format('YYYY-MM-DD HH:mm:ss') : ''}&ztgcpoOrderDeliveryDateEnd=${this.ztgcpoOrderDeliveryDate[1] ? this.moment(this.ztgcpoOrderDeliveryDate[1]).format('YYYY-MM-DD HH:mm:ss') : ''}&ztgcpoDeliveryDateStart=${this.ztgcpoDeliveryDate[0] ? this.moment(this.ztgcpoDeliveryDate[0]).format('YYYY-MM-DD HH:mm:ss') : ''}&ztgcpoDeliveryDateEnd=${this.ztgcpoDeliveryDate[1] ? this.moment(this.ztgcpoDeliveryDate[1]).format('YYYY-MM-DD HH:mm:ss') : ''}&ztgcpoFactoryName=${this.ztgcpoFactoryName}&ztgcpoiClothType=${this.ztgcpoiClothType}&ztgcpoFabriColor=${this.ztgcpoFabriColor}&ztgcpoiClientNum=${this.ztgcpoiClientNum}&ztgcpoPlanDateStart=${this.ztgcpoPlanDate[0] ? this.moment(this.ztgcpoPlanDate[0]).format('YYYY-MM-DD HH:mm:ss') : ''}&ztgcpoPlanDateEnd=${this.ztgcpoPlanDate[1] ? this.moment(this.ztgcpoPlanDate[1]).format('YYYY-MM-DD HH:mm:ss') : ''}&ztgcpoTakeOn=${this.ztgcpoTakeOn}&ztgcpoStatus=${this.ztgcpoStatus}&ztgcpoContractNo=${this.ztgcpoContractNo}
      `;
    },
    // 获取表格数据
    tableDataList() {
      this.loading = true;
      this.axios({
        url: "/dtsum/zongtong/order/planController/clothSheetSearch",
        method: "get",
        params: {
          pageSize: this.listData.pageSize, // 页大小
          pageNum: this.listData.pageNum, // 页号
          params: {
            ztgcpoPlanOrderNumber: this.ztgcpoPlanOrderNumber, // 坯布指示单号（主表的计划单号）
            ztgcpoContractNo: this.ztgcpoContractNo, // 合同号
            ztgcpoGreyClothItemNo: this.ztgcpoGreyClothItemNo, // 坯布货号
            ztgcoArticleNumber: this.ztgcoArticleNumber, // 成品品号
            ztgcpoiWeaveDetail: this.ztgcpoiWeaveDetail, // 计划单号（详情表的织造明细）
            ztgcpoOrderDeliveryDateStart: this.ztgcpoOrderDeliveryDate[0] ? this.moment(this.ztgcpoOrderDeliveryDate[0]).format('YYYY-MM-DD HH:mm:ss') : '', // 订单交期开始
            ztgcpoOrderDeliveryDateEnd: this.ztgcpoOrderDeliveryDate[1] ? this.moment(this.ztgcpoOrderDeliveryDate[1]).format('YYYY-MM-DD HH:mm:ss') : '', // 订单交期结束
            ztgcpoDeliveryDateStart: this.ztgcpoDeliveryDate[0] ? this.moment(this.ztgcpoDeliveryDate[0]).format('YYYY-MM-DD HH:mm:ss') : '', // 交货日期开始
            ztgcpoDeliveryDateEnd: this.ztgcpoDeliveryDate[1] ? this.moment(this.ztgcpoDeliveryDate[1]).format('YYYY-MM-DD HH:mm:ss') : '', // 交货日期结束
            ztgcpoiProductionCargoNum: this.ztgcpoiProductionCargoNum,
            ztgcpoFactoryName: this.ztgcpoFactoryName, // 工厂名称
            ztgcpoiClothType: this.ztgcpoiClothType, // 种类
            ztgcpoFabriColor: this.ztgcpoFabriColor, // 面料颜色
            ztgcpoiClientNum: this.ztgcpoiClientNum, // 客户款号
            ztgcpoPlanDateStart: this.ztgcpoPlanDate[0] ? this.moment(this.ztgcpoPlanDate[0]).format('YYYY-MM-DD HH:mm:ss') : '', // 计划日期开始
            ztgcpoPlanDateEnd: this.ztgcpoPlanDate[1] ? this.moment(this.ztgcpoPlanDate[1]).format('YYYY-MM-DD HH:mm:ss') : '', // 计划日期结束
            ztgcpoTakeOn: this.ztgcpoTakeOn, // 担当
            ztgcpoStatus: this.ztgcpoStatus // 状态
          }
        }
      }).then(res => {
        this.loading = false
        if (res.data.success === 1) {
          const clothSheetList = res.data.body.clothSheetList;
          this.tableData = JSON.parse(JSON.stringify(clothSheetList.list));
          console.log(this.tableData)
          this.listData.pageSize = clothSheetList.pageSize;
          this.listData.pageNum = clothSheetList.pageNum;
          this.listData.total = clothSheetList.total;
          this.userFilds = res.data.body.userFilds;
          this.tableColumns = [
            {
              title: "序号",
              type: "index",
              width: 80,
              align: "center",
              render: (h, params) => {
            return h(
              "span",
              params.index +
                (this.listData.pageNum - 1) * this.listData.pageSize +
                1
            );
          },
            },
            {
              title: "操作",
              key: "action",
              width: 100,
              align: "center",
              render: (h, params) => {
                return h("div", [
                  h(
                    "span",
                    {
                      style: {
                        fontSize: "14px",
                        color: "rgb(87, 163, 243)",
                        cursor: "pointer",
                      },
                      on: {
                        click: () => {
                          this.$router.push({ path: '/workOrderManage/clothSheetDetail', query: { ztgcpoiWeaveDetail: params.row.ztgcpoiWeaveDetail } })
                        }
                      }
                    },
                    "详情"
                  )
                ]);
              }
            },
          ]
          this.userFilds.map((item) => {
            this.tableColumnsReady.map((itemIn) => {
              if (itemIn.key === item.field){
                this.tableColumns.push(itemIn)
              }
            })
          })
        }
      }).catch(err => {
        console.log(err)
      })
    },
    // 点击表格设置
    handleClickTableSetting() {
      this.chuansuoDialogVisible = true;
    },
    // 自定义筛选
    handleClickfilter() {
      this.showSelect = !this.showSelect;
      if (this.showSelect === true) {
        // 从筛选条件跳转到多选框
        this.showUserDefined = true;
        this.checkAllGroup1 = this.checkAllGroupShow1;
      } else {
        // 从多选框跳到筛选条件
        if (this.checkAllGroupShow1.length === 0) {
          this.showUserDefined = false;
        }
      }
    },
    // 确认多选框跳转到筛选条件
    handleClickConfirm() {
      this.showSelect = false;
      this.checkAllGroupShow1 = this.checkAllGroup1;
      if (this.checkAllGroupShow1.length === 0) {
        this.showUserDefined = false;
      }
    },
    // 跳转到筛选条件的多选框
    handleClickSelectButton() {
      this.showSelect = true;
    },
    pageSizeChange(pageSize) {
      console.log('chufa')
      this.listData.pageSize = pageSize;
      this.listData.pageNum = 1;
      this.tableDataList()
    },
    pageNumberChange(pageNum) {
      this.listData.pageNum = pageNum;
      this.tableDataList()
    },
    handleCheckAll1() {
      // 全选
      if (this.indeterminate1) {
        this.checkAll1 = false;
      } else {
        this.checkAll1 = !this.checkAll1;
      }
      this.indeterminate1 = false;

      if (this.checkAll1) {
        this.checkAllGroup1 = [
          "工厂名称",
          "种类",
          "面料颜色",
          "客户款号",
          "计划日期",
          "担当",
          "状态"
        ]; // 这里改为全部的状态
      } else {
        this.checkAllGroup1 = [];
      }
      console.log(this.checkAllGroup1);
    },
    checkAllGroupChange1(data) {
      if (data.length === this.boxList1.length) {
        this.indeterminate1 = false;
        this.checkAll1 = true;
      } else if (data.length > 0) {
        this.indeterminate1 = true;
        this.checkAll1 = false;
      } else {
        this.indeterminate1 = false;
        this.checkAll1 = false;
      }
    },
    handleCheckAll2() {
      // 全选
      if (this.indeterminate2) {
        this.checkAll2 = false;
      } else {
        this.checkAll2 = !this.checkAll2;
      }
      this.indeterminate2 = false;

      if (this.checkAll2) {
        this.checkAllGroup2 = ["待穿纱", "待改机", "量产中"]; // 这里改为全部的状态
      } else {
        this.checkAllGroup2 = [];
      }
    },
    checkAllGroupChange2(data) {
      if (data.length === 3) {
        this.indeterminate2 = false;
        this.checkAll = true;
      } else if (data.length > 0) {
        this.indeterminate2 = true;
        this.checkAll = false;
      } else {
        this.indeterminate2 = false;
        this.checkAll = false;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
#clothSheet {
  font-size: $--fontsize-title;
  .filter {
    font-size: $--fontsize-small;
    display: flex;
    position: relative;
    .left {
      .filterItem {
        display: inline-flex;
        margin: 0 50px 10px 0;
        div {
          line-height: 32px;
        }
        &:last-of-type{
          float: right;
        }
      }
    }
  }
  .userDefined {
    font-size: $--fontsize-small;
    background: #F4F7FE;
    padding: 10px 0 0 8px;
    border-radius: $--radius-standard;
    position: relative;
    display: flex;
    .divide{
      display: inline-block;
      width:1px;
      height:10px;
      margin-top:2px;
      margin-right:10px;
      background-color: #dcdada;
    }
    .left {
      width: calc(100% - 150px);
      .filterItem {
        display: inline-flex;
        margin: 0 50px 10px 0;
        div {
          line-height: 32px;
        }
      }
    }
    .right {
      width: 150px;
      position: relative;
    }
  }
  .box {
    font-size: $--fontsize-small;
    .boxItem {
      margin: 10px 0;
    }
  }
  .button {
    font-size: $--fontsize-small;
    .divide{
      width:1px;
      height:10px;
      display: inline-block;
      margin-top:2px;
      margin-right:20px;
      background-color: #dcdada;
    }
    .buttonRow {
      display: flex;
      justify-content: space-between;
      margin: 10px 0;
      color:#256de6;
      .item {
        display: inline-block;
        cursor: pointer;
      }
      .left {
        .item {
          margin-right: 30px;
          &:nth-of-type(3){
            margin-right:20px;
          }
          &:nth-of-type(7){
            margin-right:20px;
          }
        }
      }
      .right {
        .item {
          margin-left: 10px;
        }
        .filterButton{
          width:120px;
          margin-right:30px;
          height:32px;
          line-height:32px;
          text-align: center;
        }
      }
    }
  }
  .page {
    font-size: $--fontsize-small;
    text-align: center;
    margin-top: 10px;
  }
}
</style>
<style lang="scss">
#clothSheet {
  .filter {
    .right {
      .ivu-btn {
        position: absolute;
        bottom: 10px;
        color: $--color-blue-standard;
      }
    }
  }
  .userDefined {
    .right {
      .cancel{
        position: absolute;
        left:0;
        bottom:5px;
      }
      .confirm {
        position: absolute;
        right: 10px;
        bottom: 5px;
      }
        .ivu-btn-default{
          color:#FFF;
          background: #256DE6;
        }
      .select_button {
        color: $--color-blue-standard;
        display: flex;
        position: absolute;
        top: calc(50% - 5px);
        transform: translateY(-50%);
        &::before {
          content: "";
          display: block;
          width: 2px;
          height: 20px;
          margin-right: 10px;
          background-color: #dcdada;
        }
      }
    }
  }
  .ivu-select-selection {
    height: 32px !important;
  }
  .ivu-select-placeholder {
    line-height: 32px !important;
    height: 32px !important;
  }
  .ivu-select-selected-value {
    line-height: 32px !important;
    height: 32px !important;
  }
  .ivu-input {
    height: 32px !important;
  }
  .ivu-input-suffix i {
    line-height: 32px !important;
  }
  .ivu-icon-ios-time-outline {
    line-height: 32px !important;
  }
  .ivu-checkbox-default {
    display: inline-block;
  }
}
</style>
